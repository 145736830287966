import { faApple } from '@fortawesome/free-brands-svg-icons';
import { faBatteryThreeQuarters, faCamera, faCoins, faExpandAlt, faHdd, faRocket, faShoppingCart, faSimCard, faWater, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import './QuestionIcon.css';

export class QuestionIcon extends Component {
    displayName = QuestionIcon.name

    constructor(probs) {
        super(probs);

        this.state = {
        };
    }

    render() {

        var cat = this.props.cat;

        var icon;

        if (cat == "os") {
            icon = faApple;
        } else if (cat == "price") {
            icon = faCoins;
        } else if (cat == "camera") {
            icon = faCamera;
        } else if (cat == "storage") {
            icon = faHdd;
        } else if (cat == "power") {
            icon = faRocket;
        } else if (cat == "akku") {
            icon = faBatteryThreeQuarters;
        } else if (cat == "size") {
            icon = faExpandAlt;
        } else if (cat == "sim") {
            icon = faSimCard;
        } else if (cat == "ip") {
            icon = faWater;
        } else if (cat == "buy") {
            icon = faShoppingCart;
        } else if (cat == "fav") {
            icon = faStar;
        }


        return (
                <FontAwesomeIcon icon={icon} className="questionIcon" />
        );
    }
}
