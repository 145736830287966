import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ModalView } from "../Tracking";
import './TopResult.css';
import { TopResultCard } from './TopResultCard';

export class TopResult extends Component {
    displayName = TopResult.name

    constructor(probs) {
        super(probs);

        this.state = {
        };
    }

    onBackButtonEvent = (e) => {
        this.props.getLastQuestion();
    }

    componentWillUnmount = () => {
        window.removeEventListener('popstate', this.onBackButtonEvent);
    }

    componentDidMount() {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);

        ModalView('/advice/TopResult');
    }

    render() {
        //holt sich Result aus probs
        var results = this.props.result;

        var result_1 = results[0];
        var result_2 = results[1];
        var result_3 = results[2];

        return (
            <div className="wrapResult">
                <Row className="justify-content-center topResultRow">
                    <Col xl="4" className="resultCols">
                        <TopResultCard result={result_2} showDetailsModal={this.props.showDetailsModal} showPriceModal={this.props.showPriceModal} />
                    </Col>

                    <Col xl="4" className="resultCols">
                        <TopResultCard result={result_1} top={true} showDetailsModal={this.props.showDetailsModal} showPriceModal={this.props.showPriceModal} />
                    </Col>

                    <Col xl="4" className="resultCols">
                        <TopResultCard result={result_3} showDetailsModal={this.props.showDetailsModal} showPriceModal={this.props.showPriceModal} />
                    </Col>
                </Row>
                <Button id="moreResultBtn" class="btn-ghost btn btn-secondary" onClick={this.props.switch}>
                    Weitere Empfehlungen
                </Button>
            </div>
        );
    }
}
