import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Event } from "../Tracking";
import './AnswerButton.css';

export class AnswerButton extends Component {
    displayName = AnswerButton.name

    render() {

        return (
            //erstellt Row für Buttons, welche gemittelt ist
            <div className="answerButtonsContainer">

                {/*
                 * für jedes Detail-Element wird ein Button erstellt 
                 * mit den jeweiligen spezifischen Eigenschaften
  
                * bei ButtonClick wird die Antwort weitergegeben
                * + ein GA Event wird gefeuert
                * */}
                {this.props.details.map(details => (
                    <Button onClick={
                        () => {
                            this.props.setAnswer(details.id);
                            this.props.changeTransitionState();
                            Event("AnswerButton", "" + this.props.text, "" + details.text);
                        }
                    } className="btn btn-outline-primary answerButtons" key={details.id}>
                        {details.text}
                    </Button>
            ))}
            </div>
        );
    }
}
