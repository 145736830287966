import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Container } from 'reactstrap';
import { Hero } from './Hero';
import { Advantages } from './Advantages';
import './Home.css';
import { initGA, PageView } from '../Tracking';
import { Cookies } from "react-cookie-consent";

export class Home extends Component {
    static displayName = Home.name;

    componentDidMount() {
        initGA();
        PageView();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>pickyIT: dein perfektes Ger&auml;t, egal ob Handy, Laptop, TV, Kamera & mehr</title>
                    <meta name="description" content="Wir finden heraus welches Ger&auml;t wirklich zu dir passt. Auf Basis der individuellen Anforderungen, Nutzung und W&uuml;nsche ein und das unabh&auml;ngig und transparent" />
                    <meta name="keywords" content="perfekt, Ger&auml;t, Handy, Laptop, TV, individuell, Anforderungen, Nutzung, W&uuml;nsche, unabh&auml;ngig, transparent" />
                </Helmet>

                <div className="content landingpage">
                    <Hero />
                </div>

                <Container className="content flex advantages">
                    <Advantages />
                </Container>
            </div>
        );
    }
}
