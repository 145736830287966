import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Event, ModalView } from "../Tracking";
import { AnswerButton } from './AnswerButton';
import { AnswerDropdown } from './AnswerDropdown';
import { AnswerTextfield } from './AnswerTextfield';
import './Question.css';
import { QuestionIcon } from './QuestionIcon';

export class Question extends Component {
    displayName = Question.name
    constructor(probs) {
        super(probs);

        this.setAnswer = this.setAnswer.bind(this);

        //Default states
        this.state = {
            showModal: false,
            textContainerHeight: 0
        }
    }

    onBackButtonEvent = (e) => {
        Event("Navigation", "QuestionDialog", "Back");
        if (this.props.question.id <= 0) {
            window.location.href = "/"
        } else {
            this.props.getLastQuestion();
        }

    }

    setAnswer(probs) {
        window.addEventListener('popstate', this.onBackButtonEvent);
        this.props.setAnswer(probs);
    }

    componentWillUnmount = () => {
        window.removeEventListener('popstate', this.onBackButtonEvent);
    }

    componentDidMount() {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);
        this.setState({ textContainerHeight: document.getElementsByClassName('questionTextContainer')[0].clientHeight })

        ModalView('/advice/QuestionDialog');
    }

    render() {
        // variable Code-Blocks (let) 
        let Answers;
        let backButton;

        /* es wird nach Antwortkategorie gefiltert die als Eigenschaft der Frage mit in der DB steht
         * die jeweilge Antwortmöglichkeit wird wird dann angezeigt
         * 
         * Eigenschaft setAnswer übermittel die Funktion aus der Componente Advice, 
         * welche die gegebene Antwort übermittelt und die nächste Frage holt*/
        if (this.props.question.answerCategory == 0) {
            Answers = <AnswerTextfield setAnswer={this.setAnswer} text={this.props.question.text} />
        }
        if (this.props.question.answerCategory == 1) {
            // details enthält die Texte der Buttons
            Answers = <AnswerButton setAnswer={this.setAnswer} text={this.props.question.text} details={this.props.question.details} changeTransitionState={this.props.changeTransitionState} />
        }
        if (this.props.question.answerCategory == 2) {
            // details enthält die Texte der Buttons
            Answers = <AnswerDropdown setAnswer={this.setAnswer} text={this.props.question.text} details={this.props.question.details} />
        }

        // die erste Frage (id = 0) hat kein zurück-Button
        if (this.props.question.id > 0) {
            backButton = <Button id="backButton"
                onClick={
                    () => {
                        Event("Navigation", "QuestionDialog", "BackBtn");
                        window.history.back();
                    }
                }
                color="link">
                <FontAwesomeIcon icon={faArrowLeft} style={{ color: "#fff", width: "auto", height: "30" }} />
            </Button>
        }

        return (
            <Row className="questionDialogContainer">
                {backButton}
                <Col xs="auto" className="questionBanner" style={{ height: this.state.textContainerHeight }}>
                    <QuestionIcon cat={this.props.question.category} />
                </Col>
                <Col className="questionContainer">
                    <Row className="questionTextContainer rowNoMargin">
                        <Row id="rowQuestionText" className="rowNoMargin">
                            { /* Fragentext wird eingefügt aus den übergebenen Probs */}
                            <h1 id="questionText">{this.props.question.text}</h1>
                        </Row>

                        { /* Button für misunderstand Question */}
                        <Button onClick={
                            () => {
                                this.setState({ showModal: true });
                                //ModalView('/advice/question-help-modal');
                                //alternativ per Event
                                Event("Navigation", "QuestionDialog", "QuestionDialogHelpModal");
                                //Verweildauer Start
                            }
                        } id="understandQuestion" color="link">
                            <h6>Was ist mit der Frage gemeint und warum ist sie wichtig? </h6>
                        </Button>

                    </Row>
                    <Row xs="auto" className="answerContainer">
                        {Answers}
                    </Row>
                </Col>

                { /* Modal für misunderstand Question */}
                <Modal className="questionDialogModal" isOpen={this.state.showModal} toggle={() => { this.setState({ showModal: false }); Event("Navigation", "QuestionDialogHelpModal", "CloseX"); }}>
                    <ModalHeader toggle={() => { this.setState({ showModal: false }); Event("Navigation", "QuestionDialogHelpModal", "CloseX"); }}>
                        {this.props.question.text}
                    </ModalHeader>
                    <ModalBody>
                        <h5>Erklärung</h5>
                        <p>{this.props.question.declaration}</p>
                        <h5>Auswirkung</h5>
                        <p>{this.props.question.impact}</p>
                    </ModalBody>
                </Modal>
            </Row>
        );
    }
}
