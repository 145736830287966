import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Footer } from '../Footer/Footer';
import { NavMenu } from '../Nav/NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        // h�ndelt NavMenu Logo
        let navbar = <NavMenu />;

        if (this.props.logoDark) {
            navbar = <NavMenu logoDark="true" />;
        }

        return (
            <div>
                {navbar}
                <Container fluid id="wrapper">
                    {this.props.children}
                </Container>
                <Footer />
            </div>
        );
    }
}
