import { faCheck, faStar, faTimes, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import './TopResultCard.css';
import { Event } from "../Tracking";

export class TopResultCard extends Component {
    displayName = TopResultCard.name

    constructor(probs) {
        super(probs);

        this.state = {
        };
    }

    render() {

        var handy = this.props.result[0];
        var models = this.props.result;
        //holt sich Result aus probs
        var device = handy.handy;
        var score = handy.score;
        var fulfilledReqs = handy.fulfilledReqs;
        var unfulfilledReqs = handy.unfulfilledReqs;
        var extraSpecs = handy.extraSpecs;

        let content =
            <div className="topResultCardContainer">
                <div className="topResultCardBanner">
                    <div className="topResultCardScore">
                        <FontAwesomeIcon icon={faUserCheck} color="white" fixedWidth className="resultCardIcon" />
                        {score}%
                    </div>
                </div>
                <Row className="topResultCardContent">
                    <div className="resultCardText">
                        <h3 className="resultCardHeader">{device.device_name} ({device.storage}GB)</h3>
                        <h5 className="resultCardSubline">{device.brand.name}</h5>
                    </div>
                    <div className="resultCardSpecs">
                        <div className="wrapImgProps">
                            <div>
                                <img src={device.image_url} alt="Card image cap" className="topResultCardImg" />                    
                            </div>
                            <div className="topResultCardProbs">
                                <Row className="probsRow topResultFulfilled">
                                    <Col>
                                        {fulfilledReqs.slice(0, 3).map((Req, index) => (
                                            <Row className="probsRowInner">
                                                <FontAwesomeIcon icon={faCheck} color="#32DE84" fixedWidth className="resultCardIcon" />
                                                <h6>{Req}</h6>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                                <Row className="probsRow topResultUnfulfilled">
                                    <Col>
                                        {unfulfilledReqs.slice(0, 2).map((Req, index) => (
                                            <Row className="probsRowInner">
                                                <FontAwesomeIcon icon={faTimes} color="#de3234" fixedWidth className="resultCardIcon" />
                                                <h6>{Req}</h6>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                                {/* <Row className="probsRow topResultExtraSpecs">
                                    <Col>
                                        {extraSpecs.map((Req, index) => (
                                            <Row className="probsRowInner">
                                                <FontAwesomeIcon icon={faStar} color="orange" fixedWidth className="resultCardIcon" />
                                                <h6>{Req}</h6>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row> */ }
                            </div>
                        </div>
                        <div className="resultmodelsStorageContainer">
                            {models.map((model, index) => (
                                <Row className="resultmodelsStorageRow">
                                    <Col className="resultmodelsStorageStorageCol">{model.handy.storage}GB</Col>
                                    <Col className="resultmodelsStorageStorageCol">{model.score}%</Col>
                                </Row>
                            ))}
                        </div>
                    </div>
                    <div className="resultCardBtnContainer">
                        <Button onClick={() => { this.props.showPriceModal(device); Event("Navigation", "Result", "DetailsBtn");}} className="btn-prim">Preisvergleich</Button>
                        <Button onClick={() => { this.props.showDetailsModal(models); Event("Navigation", "Result", "DetailsBtn");}} className="btn-ghost">Details</Button>
                    </div>
                </Row> 
            </div>;

        let normalContainer =
            <div className="topResultCardWrapper">
                {content}
            </div>;

        let topContainer =
            <div className="topResultCardTopWrapper topResultCardWrapper">
                {content}
            </div>;

        let show;

        if (this.props.top) {
            show = topContainer;
        } else {
            show = normalContainer;
        }


        return (
            <div>
                {show}
            </div>
        );
    }
}
