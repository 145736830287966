import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { Event } from "../Tracking";
import './AnswerTextfield.css';

export class AnswerTextfield extends Component {
    displayName = AnswerTextfield.name

    constructor(props) {
        super(props);

        // händelt Textfeld Text
        this.state = { value: '' };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        // händelt TextfeldEingabe
        this.setState({ value: event.target.value });
    }

    render() {
        return (
            <div className="answerButtonsContainer">
                <Input type="text" placeholder="zB. Kamera" onKeyPress={event => {
                    if (event.key === 'Enter') {
                        this.props.setAnswer(this.state.value);
                        Event("AnswerTextfield", "" + this.props.text, "" + this.state.value);
                    }
                    }
                } value={this.state.value} onChange={this.handleChange} name="text" id="answerTextfield" />
                <Button onClick={
                    () => {
                        this.props.setAnswer(this.state.value);
                        Event("AnswerTextfield", "" + this.props.text, "" + this.state.value);
                    }
                } className="answerButtons" color="primary">
                    Eingabe
                </Button>
            </div>
        );
    }
}
