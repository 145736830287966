import React, { Component } from 'react';
import './Survey.css';
import Helmet from 'react-helmet';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { initGA, PageView } from '../../components/Tracking';


export class Survey extends Component {
    displayName = Survey.name


    componentDidMount() {
        initGA();
        PageView();
    }

    render() {
        return (
            <div id='surveyContainer'>
                <Helmet>
                    <title>Survey - pickyIT</title>
                    <meta name="description" content="Die Inhalte unserer Seiten wurden mit gr&ouml;ßter Sorgfalt erstellt." />
                    <meta name="keywords" content="Impressum, Inhalte, Sorgfalt" />
                </Helmet>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfViig_FJEtdWSyrL7Yn_Ons9hU3bJUUMkBLs9w63x34m_kDA/viewform?embedded=true" frameBorder="0">Wird geladen…</iframe>

            </div>

        );
    }
}