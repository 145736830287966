import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { CallToAction } from './CallToAction';
import './Advantages.css';

export class Advantages extends Component {

    render() {

        return (

            <div id="advantages" className="container">

                <div className="row">
                    <div className="col-12">
                        <h1>Deine Vorteile</h1>
                    </div>
                </div>

                <div className="row mb-2">
                    <div class="col-12 col-md-6 advantage">
                        <FontAwesomeIcon icon={faUser} size="5x" className="icon" />
                        <div className="text">
                            <h2>Individuell</h2>
                            <p>Wir erfassen all deine persönlichen Anforderungen und finden somit das perfekte Handy für deine Nutzungsgewohnheiten.</p> 
                        </div>
                    </div>
                    <div class="col-12 col-md-6 advantage">
                        <FontAwesomeIcon icon={faCoins} size="5x" fixedWidth className="icon" />
                        <div className="text">
                            <h2>Kostenlos</h2>
                            <p>Beantworte einige Fragen und wir zeigen dir deine persönlichen Empfehlungen an. Unser Service ist kostenlos.</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 advantage">
                        <FontAwesomeIcon icon={faTachometerAlt} size="5x" fixedWidth className="icon" />
                        <div className="text">
                            <h2>Schnell</h2>
                            <p>Du siehst die für dich empfohlenen Geräte nach wenigen Klicks.</p>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 advantage">
                        <FontAwesomeIcon icon={faComment} size="5x" fixedWidth className="icon" />
                        <div className="text">
                            <h2>Transparent</h2>
                            <p>Wir zeigen dir, wie das Ergebnis zustande kommt und warum die Empfehlungen ideal zu dir passen.</p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <CallToAction site='Landingpage' pos="second" />
                </div>


            </div>

        );

    }

}