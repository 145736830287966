import React, { Component } from 'react';
import { Cookies } from "react-cookie-consent";
import Helmet from 'react-helmet';
import { Col, Row } from 'reactstrap';
import { initGA, PageView } from '../../components/Tracking';
import imgDefault from '../../data/person.png';
import imgJonas from '../../data/imgJonas.png';
import './About.css';
import { AboutTeamItem } from './AboutTeamItem';

export class About extends Component {
    displayName = About.name

    componentDidMount() {
        initGA();
        PageView();
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>About - pickyIT</title>
                    <meta name="description" content="Wir sind ein junges Startup aus Wirtschaftsinformatik Studenten der Technischen Universit&auml;t Darmstadt. Die Idee hingegen existiert schon seit Jahren." />
                    <meta name="keywords" content="Startup, Wirtschaftsinformatik, Darmstadt, Idee" />
                </Helmet>

                <div className="aboutContainer">
                    <div className="divPadding">
                        <h2>Die Vision</h2>
                        <span className="aboutVisionText">“Wir empfehlen jedem Käufer das für ihn am besten passende Produkt.”</span>
                    </div>
                    <div className="divPadding aboutProblemContainer">
                        <h2>Die Chance</h2>
                        <p>Vor jedem Kauf eines Tech&shy;nik&shy;produkts stellt man sich die Frage, welches Gerät am besten für einen ge&shy;eignet ist.
                        Der Ver&shy;gleich ver&shy;schiede&shy;ner An&shy;ge&shy;bote ist um&shy;ständ&shy;lich und eine Ent&shy;schei&shy;dung ist oft schwer zu fällen. Be&shy;son&shy;ders
                        un&shy;an&shy;ge&shy;nehm ist die Suche, wenn man sich wenig mit tech&shy;nischen Fach&shy;be&shy;griffen aus&shy;ein&shy;ander&shy;setzen möchte. Da die
                        Beratung in Fach&shy;ge&shy;schäften häufig nicht gut ist, springen Technik&shy;be&shy;geisterte aus dem Freundes- und Bekannten&shy;kreis
                            ein, um zu beraten. Jedoch haben auch diese nicht immer den Über&shy;blick über die aktuellsten Angebote.</p>
                    </div>
                    <div className="divPadding aboutSolutionContainer">
                        <h2>Die Lösung</h2>
                        <p>Bei der online Beratung durch PickyIT wird ein persön&shy;liches Anforder&shy;ungs&shy;profil mit wenigen, opti&shy;mierten Fragen er&shy;stellt.
                        Zur Be&shy;ant&shy;wortung der Fragen ist kein Fach&shy;wissen nötig. PickyIT ver&shy;gleicht aktuelle Geräte auto&shy;matisch anhand der persön&shy;lichen
                        Anforde&shy;rungen und spricht eine Empfehlung für drei Geräte aus. An&shy;schließend kann der Nutzer die empfohlenen Geräte vergleichen
                            und trifft zuletzt die richtige Kauf&shy;entscheidung.</p>
                    </div>
                    <div className="aboutTeamContainer">
                        <h2>Das Team</h2>
                        <Row className='flex'>
                            <Col xs={12} sm={6} md={4} lg={2}>
                                <AboutTeamItem imgSrc={imgJonas} name="Jonas" role="Gr&uuml;nder / Backend" />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div >
        );
    }
}