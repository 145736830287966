import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Event } from "../Tracking";
import './AnswerDropdown.css';

export class AnswerDropdown extends Component {
    displayName = AnswerDropdown.name

    constructor(probs) {
        super(probs);

        //Default states
        this.state = {
            dropdownOpen: false
        }
    }

    toggle() {
        var dropdownOpen = this.state.dropdownOpen;
        this.setState({ dropdownOpen: !{dropdownOpen}});
    }

    render() {

        var dropdownOpen = this.state.dropdownOpen;

        return (
            //erstellt Row für Buttons, welche gemittelt ist
            <div className="answerButtonsContainer">

                {/*
                 * für jedes Detail-Element wird ein Button erstellt 
                 * mit den jeweiligen spezifischen Eigenschaften
                 * */}
                {this.props.details.map(details => (

                    <div>

                        {/* 
                         * bei ButtonClick wird die Antwort weitergegeben 
                         * + ein GA Event wird gefeuert 
                         * */}
                        <Button onClick={
                            () => {
                                this.props.setAnswer(details.id);
                                Event("AnswerButton", "" + this.props.text, "" + details.text);
                            }
                        } className="btn btn-outline-primary answerButtons" key={details.id}>
                            {details.text}
                        </Button>
                    </div>
                ))}


                <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.setState({ dropdownOpen: true })}>
                    <DropdownToggle caret>
                        Dropdown
        </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Header</DropdownItem>
                        <DropdownItem>Some Action</DropdownItem>
                        <DropdownItem disabled>Action (disabled)</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Foo Action</DropdownItem>
                        <DropdownItem>Bar Action</DropdownItem>
                        <DropdownItem>Quo Action</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}
