import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import './MoreResult.css';
import { MoreResultCard } from './MoreResultCard';
import { ModalView } from "../Tracking";


export class MoreResult extends Component {
    displayName = MoreResult.name

    constructor(probs) {
        super(probs);

        this.isBackButtonClicked = false;

        this.state = {
        };
    }

    onBackButtonEvent = (e) => {
        this.props.switch();
        /*
        e.preventDefault();
        if (!this.isBackButtonClicked) {

            if (window.confirm("Do you want to save your changes")) {
                this.isBackButtonClicked = true;
                // your custom logic to page transition,like react-router-dom history.push()
                this.props.switch();
            } else {
                window.history.pushState(null, null, window.location.pathname);
                this.isBackButtonClicked = false;
            }
        }*/
    }

    componentWillUnmount = () => {
        window.removeEventListener('popstate', this.onBackButtonEvent);
    }

    componentDidMount() {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);

        ModalView('/advice/MoreResult');
    }

    render() {
        //holt sich Result aus probs
        var results = this.props.result;

        return (
            <div className="wrapResult">
                <Row className="justify-content-center">
                    {results.map((result, index) => (
                        <Col xs="12" lg="6" xl="4" className="moreResultCols">
                            <MoreResultCard result={result} showDetailsModal={this.props.showDetailsModal} showPriceModal={this.props.showPriceModal} />
                        </Col>
                    ))}
                </Row>
                <Button id="moreResultBtn" class="btn-ghost btn btn-secondary" onClick={this.props.switch}>
                    Zurück zu den Besten
                </Button>
            </div>
        );
    }
}
