import React, { Component } from 'react';
import { CallToAction } from './CallToAction';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPollH } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faAward } from '@fortawesome/free-solid-svg-icons'

import '../../custom.css';
import './Hero.css';

export class Hero extends Component {
    static displayName = Hero.name;

    render() {
        return (

            /* Text und Buttons auf HeroImage */
            <div className="hero-text flex">
                <h1>
                    Wir finden das <b>Handy</b>, <br />
                    das wirklich zu <b>dir</b> passt!
                </h1>
                <hr align="left" width="90" className="divider" />

                { /* restlicher Content auf HeroImage */}
                <div className="hero-facts">
                    <div className="mb-4">
                        <Col sm='12' className="mb-4">
                            <h2>Und so gehts...</h2>
                        </Col>
                        <Col sm='12' className="mb-4">
                            <FontAwesomeIcon icon={faPollH} size="3x" fixedWidth className="hero-icon"/>
                            <span>Du beantwortest uns einige Fragen.</span>
                        </Col>
                        <Col sm='12' className="mb-4">
                            <FontAwesomeIcon icon={faChartLine} size="3x" fixedWidth className="hero-icon"/>
                            <span>Wir vergleichen aktuelle Handys verschiedener Marken für dich.</span>
                        </Col>
                        <Col sm='12' className="mb-4">
                            <FontAwesomeIcon icon={faAward} size="3x" fixedWidth className="hero-icon" />
                            <span>Du bekommst deine persönlichen Empfehlungen.</span>
                        </Col>
                    </div>
                    <div className="mb-4" className="flex">
                        <CallToAction site="Landingpage" pos="first" />
                    </div>
                </div>
            </div>
        );
    }
}
