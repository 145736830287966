import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Button, Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle, Col, Container, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import './TopResultCard.css';
import './MoreResultCard.css';
import { Event } from "../Tracking";

export class MoreResultCard extends Component {
    displayName = MoreResultCard.name

    constructor(probs) {
        super(probs);

        this.state = {
        };
    }

    render() {
        var handy = this.props.result[0];
        var models = this.props.result;
        //holt sich Result aus probs
        var device = handy.handy;
        var fulfilledReqs = handy.fulfilledReqs.slice(0,3);

        return (
            <div className="moreResultCardContainer">
                <div className="resultCardText">
                    <h3 className="resultCardHeader">{device.device_name} ({device.storage}GB)</h3>
                    <h5 className="resultCardSubline">{device.brand.name}</h5>
                </div>
                <div className="resultCardSpecs">
                    <div className="wrapImgProps">
                        <div>
                            <img src={device.image_url} alt="Card image cap" className="moreResultCardImg" />
                        </div>
                    

                         <div className="moreResultCardProbs">
                            <Row className="probsRow topResultFulfilled">
                                <Col>
                                    {fulfilledReqs.slice(0, 3).map((Req, index) => (
                                        <Row className="probsRowInner">
                                            <FontAwesomeIcon icon={faCheck} color="#32DE84" fixedWidth className="resultCardIcon" />
                                            <h6>{Req}</h6>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="resultmodelsStorageContainer">
                        {models.map((model, index) => (
                            <Row className="resultmodelsStorageRow">
                                <Col className="resultmodelsStorageStorageCol">{model.handy.storage}GB</Col>
                                <Col className="resultmodelsStorageStorageCol">{model.score}%</Col>
                            </Row>
                        ))}
                    </div>
                </div>
                <div className="resultCardBtnContainer">
                    <Button onClick={() => { this.props.showPriceModal(device); Event("Navigation", "Result", "DetailsBtn"); }} className="btn-prim">Preisvergleich</Button>
                    <Button onClick={() => { this.props.showDetailsModal(models); Event("Navigation", "Result", "DetailsBtn"); }} className="btn-ghost">Details</Button>
                </div>
            </div>
        );
    }
}
