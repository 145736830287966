import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { ReactComponent as Logo } from '../../data/Logo_pickyIT_black.svg';
import { ReactComponent as LogoDark } from '../../data/Logo_pickyIT_white.svg';
import { MenuLinks } from './MenuLinks.js';
import './NavMenu.css';


export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        //bindet Funktion an Componente
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isOpen: false
        };

        this.menuToggle = this.menuToggle.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleDocumentClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick, false);
    }

    handleDocumentClick(e) {
        if (!this.refs.root.contains(e.target) && this.state.isOpen === true) {
            this.setState({
                isOpen: false
            });
        };
    }

    menuToggle(e) {
        e.stopPropagation();
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        let menuStatus = this.state.isOpen ? 'isopen' : '';        

        //setzt Navbar White/Light als default
        let dark = true;
        if (window.location.pathname == '/') dark = false;
        const navColoring = dark ? 'nav-dark' : 'nav-light';
        const hambColoring = dark ? 'hamb-dark' : 'hamb-light';

        let navbar =
            <Navbar className="navbar-expand-xl navbar-toggleable-sm">
                <Container fluid>
                    <NavbarBrand tag={Link} to="/">
                        { this.props.logoDark ? <LogoDark id="logoNav" /> : <Logo id="logoNav" /> }
                    </NavbarBrand>
                    <div className="hambclicker" onClick={this.menuToggle}>
                        <div id="hambmenu" className={menuStatus}>
                            <span className={hambColoring}></span>
                            <span className={hambColoring}></span>
                            <span className={hambColoring}></span>
                            <span className={hambColoring}></span>
                        </div>
                    </div>
                    <MenuLinks menuStatus={menuStatus} navColoring={navColoring} toggleMenu={this.menuToggle} />
                </Container>
            </Navbar>

        return (
            <header ref="root">
                {navbar}
            </header>
        );

    }
}
