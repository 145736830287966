import React, { Component } from 'react';
import { faShareAlt, faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton, RedditIcon, RedditShareButton } from "react-share";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Event } from "../Tracking";
import './SharingButtons.css';

export class SharingButtons extends Component {
    displayName = SharingButtons.name

    constructor(probs) {
        super(probs);
        this.state = {
            show: false
        }
    }

    render() {

        var shareUrl = "pickyit.de";
        var shareSize = 32;
        var shareRound = true;

        var related = ["pickyIT", "edhor1608"];

        var device = this.props.device;

        var quote = "Mir wurde das " + device.brand.name + " " + device.device_name + " empfohlen! Welches aktuelle Smartphone passt zu dir? Mach den Test! #smartphone #handy #challenge"

        var fbButton = <FacebookShareButton url={shareUrl} quote={quote} onClick={() => Event("Navigation", "SharingBtn", "Facebook")} ><FacebookIcon size={shareSize} round={shareRound} /></FacebookShareButton>;
        var waButton = <WhatsappShareButton url={shareUrl} quote={quote} onClick={() => Event("Navigation", "SharingBtn", "WhatsApp")} ><WhatsappIcon size={shareSize} round={shareRound} /></WhatsappShareButton>;
        var twButton = <TwitterShareButton url={shareUrl} title={quote} related={related} via="pickyIT" onClick={() => Event("Navigation", "SharingBtn", "Twitter")}><TwitterIcon size={shareSize} round={shareRound} /></TwitterShareButton>;
        var rdButton = <RedditShareButton url={shareUrl} title={quote} related={related} via="pickyIT" onClick={() => Event("Navigation", "SharingBtn", "Reddit")}><RedditIcon size={shareSize} round={shareRound} /></RedditShareButton>;


        return (
            <div id="sharingBtns">
                <span onClick={() => this.setState({ show: true })} className="sub-header-part">
                    Ergebnis teilen
                    <FontAwesomeIcon icon={faShareAlt} color="#474747" fixedWidth style={{ "marginLeft": "10px" }} />
                </span>
                <Modal className="shareModal" isOpen={this.state.show} centered={true} toggle={() => this.setState({ show: false })}>
                    <div className="topResultCardBanner"></div>
                    { /*Eigenschaft Toggle händelt "X"-Button oben rechts im modalDetails*/}
                    < ModalHeader className="resultDetailsModalHeader" toggle={() => this.setState({ show: false })}>
                        Teile dein Ergebnis mit deinen Freunden und Bekannten!
                    </ModalHeader >

                    <ModalBody className="sharingModalBody">
                        <div className="sm-plattform">
                            {fbButton}
                            Facebook
                        </div>
                        <div className="sm-plattform">
                            {waButton}
                            WhatsApp
                        </div>
                        <div className="sm-plattform">
                            {twButton}
                            Twitter
                        </div>
                        <div className="sm-plattform">
                            {rdButton}
                            Reddit
                        </div>
                    </ModalBody>
                </Modal >
            </div>
        );
    }
}


/*<div className="flex">
    {fbButton} {waButton} {twButton} {rdButton}
</div>*/