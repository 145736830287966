import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Event } from "../Tracking";
import './CallToAction.css';

export class CallToAction extends Component {

    render() {
        return (
            <Link onClick={() => Event("CTA", "" + this.props.site, "" + this.props.pos)} className="btn cta" to="/Advice" role="button">Starten</Link>
        );

    }

}