import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import LogoFooter from '../../data/Logo_pickyIT_white.svg';
import { Event } from "../Tracking";
import './Footer.css';

export class Footer extends Component {
    displayName = Footer.name

    render() {
        return (
            <div id="Footer" class="pt-3 pb-3">

                <Row className='ml-4 mr-4 justify-content-around'>
                    <Col md='3' lg='2' className='d-none d-md-block'>
                        <img src={LogoFooter} className='logo'/>
                    </Col>
                    <Col xs='12' md='3' lg='2'>
                        <h3>Menu</h3>
                        <ul>
                            <li><Link onClick={() => Event("Navigation", "FooterMenu", "Home")} to="/">Startseite</Link></li>
                            <li><Link onClick={() => Event("Navigation", "FooterMenu", "Advice")} to="/Advice">Beratung</Link></li>
                            <li><Link onClick={() => Event("Navigation", "FooterMenu", "About")} to="/About">Über uns</Link></li>
                        </ul>
                    </Col>
                    <Col xs='12' md='4' lg='3'>
                        <h3>Seiteninformation</h3>
                        <ul>
                            <li><Link onClick={() => Event("Navigation", "FooterSiteInfo", "Privacy")} to="/Privacy">Datenschutzerklärung</Link></li>
                            <li><Link onClick={() => Event("Navigation", "FooterSiteInfo", "Imprint")} to="/Imprint">Impressum</Link></li>
                            <li><Link onClick={() => Event("Navigation", "FooterSiteInfo", "Contact")} to="/Contact">Kontakt</Link></li>
                        </ul>
                    </Col>
                    <Col xs='12' md='2' lg='3'>
                        <ul className="social-media-buttons">
                            <li class='d-inline mr-2 mb-2'>
                                <ReactGA.OutboundLink eventLabel="FacebookFooter" to="https://www.facebook.com/pickit2018/" target="_blank" >
                                    <FontAwesomeIcon icon={faFacebook} size="2x" fixedWidth />
                                </ReactGA.OutboundLink>
                            </li>
                            <li class='d-inline mr-2 mb-2'>
                                <ReactGA.OutboundLink eventLabel="InstagramFooter" to="https://www.instagram.com/pickyit.de/" target="_blank" >
                                    <FontAwesomeIcon icon={faInstagram} size="2x" fixedWidth />
                                </ReactGA.OutboundLink>
                            </li>
                            <li class='d-inline mr-2 mb-2'>
                                <ReactGA.OutboundLink eventLabel="TwitterFooter" to="https://twitter.com/picky_it" target="_blank" >
                                    <FontAwesomeIcon icon={faTwitter} size="2x" fixedWidth />
                                </ReactGA.OutboundLink>
                            </li>
                            <li class='d-inline mr-2 mb-2'>
                                <ReactGA.OutboundLink eventLabel="LinkedInFooter" to="https://www.linkedin.com/company/pickyit" target="_blank" >
                                    <FontAwesomeIcon icon={faLinkedin} size="2x" fixedWidth />
                                </ReactGA.OutboundLink>
                            </li>
                        </ul>
                    </Col>
                </Row>    

                <hr />

                <span className='ml-4'>&copy; PickyIT {new Date().getFullYear()}</span>

            </div>    
        );
    }
}
