import { faHome, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { Event } from "../Tracking";

export class MenuLinks extends Component {
    constructor(props) {
        super(props);
        // Any number of links can be added here
        this.state = {
            links: [{
                text: 'Startseite',
                link: '/',
                icon: faHome
            }, {
                text: 'Beratung',
                link: '/Advice',
                icon: faQuestionCircle
            }, {
                text: 'Über uns',
                link: '/About',
                icon: faInfoCircle
            }]
        }
    }
    render() {
        let links = this.state.links.map((link, i) => <NavItem key={i + 1}><FontAwesomeIcon icon={link.icon} size="2x" className="nav-icon" fixedWidth /><NavLink onClick={
            () => {
                this.props.toggleMenu();
                // Trackt Btn Klick + Seite auf der die Nav genutzt wird
                Event("Navigation", "Navbar", "");
            }} tag={Link} to={link.link} className={this.props.navColoring}>{link.text}</NavLink></NavItem>);

        return (
            <div className={this.props.menuStatus} id='menu'>
                <ul>
                    {links}
                </ul>
            </div>
        )
    }
}
