import React, { Component } from 'react';
import { Route } from 'react-router';
import { Home } from './components/Home/HomeLayout';
import { Imprint } from './components/imprint/imprint';
import { About } from './components/About/About';
import { Privacy } from './components/privacy/privacy'
import { Contact } from './components/Contact/Contact'
import { Layout } from './components/MainLayout/Layout';
import { initGA, PageView } from './components/Tracking';
import './custom.css';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Advice } from './components/advice/Advice';
//import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Survey } from './components/Survey/Survey';


export default class App extends Component {
    static displayName = App.name;

    /*componentDidMount() {
    }*/

    render() {

        var logoDark = false;

        if (window.location.pathname == '/') {
            logoDark = true;
            console.log(window.location.pathname)
        }

        return (
            <div id="app">
                <CookieConsent
                    location="bottom"
                    buttonText="OK"
                    style={{ background: "#2B373B" }}
                    buttonClasses="btn btn-primary"
                    expires={150}
                    //disableButtonStyles={true}
                    onAccept={() => {
                        initGA();
                        PageView();
                    }}
                >
                    <h4>Verwendung von Cookies</h4>
                    <span>Um unsere Webseite f&#xFC;r Sie optimal zu gestalten und fortlaufend verbessern zu k&#xF6;nnen, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer Datenschutzerkl&#xE4;rung.</span>
                </CookieConsent>
                <Layout logoDark={logoDark}>
                    <Route exact path='/' component={Home} />
                    {/*ruft auch Home Componente auf, aber setzt zus�tzlich das Advice Flag, um den AdviceDialog anzuzeigen, statt Hero
                    {/*<Route path='/beratung' render={(props) => <Home {...props} advice={true} />} />*/}
                    <Route path='/advice' component={Advice} />
                    <Route path='/imprint' component={Imprint} />
                    <Route path='/about' component={About} />
                    <Route path='/survey' component={Survey} />
                    <Route path='/privacy' component={Privacy} />
                    <Route path='/contact' component={Contact} />
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                </Layout>
            </div>
        );
    }
}
