import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Col, Progress, Row, Button } from 'reactstrap';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Swipeable } from "react-swipeable";
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as BluredDialog } from '../../data/BluredQuestionDialog.svg';
import './Advice.css';
import { Question } from './Question';
import { Result } from './Result';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { initGA, PageView } from '../../components/Tracking';
import { SharingButtons } from '../SharingButtons/SharingButtons';
import { Link } from 'react-router-dom';

import { config } from '../../components/Constants';

var apiurl = config.url.API_URL;

export class Advice extends Component {
    displayName = Advice.name

    constructor(probs) {
        super(probs);

        //Methode an Klasse binden, sodass Kindern zugreifen können
        this.setAnswer = this.setAnswer.bind(this);
        this.getLastQuestion = this.getLastQuestion.bind(this);
        this.setTopResult = this.setTopResult.bind(this);

        //Default states
        this.state = {
            topResult: {},
            isResult: false,
            topResultIsSet: false,

            bluredRight: true,
            bluredLeft: false,
            //showProgress: true,

            //fetching
            error: null,
            isLoaded: true,
            responseQuestion: {},
            responseResult: {},
            responseIsSet: true,

            // Request
            RequestFlagStart: true,
            RequestQuestion: [],
            RequestAnswer: [],
            Progress: 0,

            transitionState: true,
            direction: 'forsrds'
        };
    }

    setTopResult(topResult) {
        this.setState({ topResult: topResult });
        this.setState({ topResultIsSet: true });
    }

    changeTransitionState() {
        this.animateCard('forwards');
    }

    animateCard(direction) {
        this.setState({ direction: direction }, () => {
            this.setState({ transitionState: !this.state.transitionState });
        });
    }

    getLastQuestionAnimated() {
        if (this.state.responseQuestion.id > 0) {
            this.getLastQuestion();
            this.animateCard('backwards');
        }
    }

    handleLeftSwipe() {
        // do nothing
    }


    setAnswer(answer) {
        // fügt die gegebene Antwort den vorherigen Antworten hinzu
        this.setState({
            RequestAnswer: this.state.RequestAnswer.concat([answer])
        });

        // holt nächste Frage
        this.getQuestion(this.state.RequestQuestion);
    }

    // holt vorherige Frage, indem damit ungültige Antworten bzw die aktuelle Frage gelöscht werden
    // es wird also der Zustand wie vor der jetzt gewollten Frage hergestellt
    getLastQuestion() {
        var answerLength = Object.keys(this.state.RequestAnswer).length

        var questionLength = Object.keys(this.state.RequestQuestion).length

        var reqAn = this.state.RequestAnswer.slice(0, answerLength - 1);

        var reqQu = this.state.RequestQuestion.slice(0, questionLength - 2);

        this.setState({
            RequestAnswer: reqAn
        });
        this.setState({
            RequestQuestion: reqQu
        });

        this.getQuestion(reqQu);
    }

    //Holt n&auml;chste Frage
    getQuestion(RequestQuestion) {

        if (RequestQuestion == null || Object.keys(RequestQuestion).length <= 0) {
            //erstellt request body
            var request = {
                "flagStart": true,
                "question": RequestQuestion,
            }
        } else {
            //erstellt request body
            var request = {
                "flagStart": this.state.RequestFlagStart,
                "question": RequestQuestion,
            }
        }

        //resetet states
        this.setState({
            error: null,
            isLoaded: false,
            responseQuestion: {},
            responseIsSet: false
        });

        //nötiger Request Header
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        //wandelt request object in JSON um
        var raw = JSON.stringify(request);

        //setzt nötige options (beinhaltet body und header)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        //sendet request an URL inkl. der Options und setzt empfangenen Response als state
        fetch(apiurl+'/api/question/getQuestion', requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        responseQuestion: result,
                        Progress: result.progress
                    });
                },

                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    //beim ersten aufrufen der Componente wird Frage mit default requestOptions geholt (erste Frage)
    componentDidMount() {
        initGA();
        PageView();
        this.getQuestion();
    }

    //h&auml;ndelt aktionen nach empfangener Frage
    setOnResponse(probs) {
        var answers = this.state.RequestAnswer;

        if (!this.state.responseIsSet) {

            //falls FlagEnd gesetzt ist wird Ergebnis geholt und angezeigt
            if (probs.flagEnd) {
                this.setState({
                    children: <Result setTopResult={this.setTopResult} probsAnswers={answers} getLastQuestion={this.getLastQuestion} />, responseIsSet: true
                });
                this.setState({ bluredRight: false });
                this.setState({ isResult: true });
                //this.setState({ showProgress: false });
            }
            //ansonsten wird empfangene Frage an Question Componente übergeben inkl. der setAntwort-Methode
            else {
                this.setState({ isResult: false });
                this.setState({
                    children: <Question question={probs} getLastQuestion={() => this.getLastQuestionAnimated()} setAnswer={this.setAnswer} changeTransitionState={() => this.changeTransitionState()} />, responseIsSet: true
                });
            }

            //aktualisiert requestOptions
            this.setState({ RequestFlagStart: false });

            if (this.state.responseQuestion.id == 0) {
                this.setState({ bluredLeft: false });
            } else {
                this.setState({ bluredLeft: true });
            }

            this.setState({
                RequestQuestion: this.state.RequestQuestion.concat([probs])
            });
        }
    }

    render() {

        let content;
        let bluredLeft;
        let bluredRight;
        let progress = this.state.Progress;
        let progressBar;
        let SharingBtns;

        //Fetching-Loading-Conditions
        if (this.state.error) {
            content = <div className="advice">Error: {this.state.error.message}</div>;
        } else if (!this.state.isLoaded) {
            // leaves old contents standing
            content = this.state.children;
        } else {
            content = this.state.children
            //Here question is successfully loaded and can be passed
            this.setOnResponse(this.state.responseQuestion);
        }

        //Blured Pics
        if (this.state.bluredLeft) {
            bluredLeft = <BluredDialog onClick={this.getLastQuestion} className="BluredDialog BluredDialog-left" />
        }
        if (this.state.bluredRight) {
            bluredRight = <BluredDialog className="BluredDialog BluredDialog-right" />
        }

        //Progressbar - only if questions
        if (!this.state.isResult) {
            progressBar = <Progress className="adviceProgress" value={progress} animated />

        } else {
            if (this.state.topResultIsSet) {
                SharingBtns = <SharingButtons device={this.state.topResult.handy} />
            }
        }

        return (
            <div className="advice">
                <Helmet>
                    <title>Beratung - pickyIT</title>
                    <meta name="description" content="Wir finden heraus welches Ger&auml;t wirklich zu dir passt. Auf Basis der individuellen Anforderungen, Nutzung und W&uuml;nsche ein und das unabh&auml;ngig und transparent" />
                    <meta name="keywords" content="perfekt, Ger&auml;t, Handy, Laptop, TV, individuell, Anforderungen, Nutzung, W&uuml;nsche, unabh&auml;ngig, transparent" />
                </Helmet>

                <Row className="adviceContent rowNoMargin ">
                    <Col sm="1" xl="2"></Col>
                    <Col>
                        <div className="adviceHeader">
                            {!this.state.isResult &&
                                <>
                                    <h3>Dein perfektes Handy</h3>
                                    <span>Bitte beantworte uns ein paar Fragen zu deiner Handynutzung.</span>
                                </>
                            }
                            {!this.state.isResult ||
                                <div>
                                    <h3>Unsere top Empfehlungen für dich.</h3>
                                    <div className="sub-header">
                                        <Link target='_blank' to='/Survey' className="sub-header-part" style={{ "textAlign": "right" }}>
                                            Feedback geben
                                            <FontAwesomeIcon icon={faComments} color="#474747" fixedWidth style={{ "marginLeft": "10px" }} />
                                        </Link>
                                        <span style={{ margin: "0 10px" }}>|</span>
                                        <span className="sub-header-part" style={{ "textAlign": "left" }}>
                                            {SharingBtns}
                                        </span>
                                    </div>
                                    
                                </div>
                            }
                            {progressBar}
                        </div>
                        <SwitchTransition mode={"out-in"}>
                            <CSSTransition
                                key={this.state.transitionState}
                                addEndListener={(node, done) => {
                                    node.addEventListener("transitionend", done, false);
                                }}
                                classNames={this.state.direction}
                            >
                                <Swipeable onSwipedRight={() => this.getLastQuestionAnimated()} onSwipedLeft={() => this.handleLeftSwipe()}>
                                    <div>{content}</div>
                                </Swipeable>

                            </CSSTransition>
                        </SwitchTransition>
                    </Col>
                    <Col sm="1" xl="2"></Col>
                </Row>

            </div>
        );

    }
}
