import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Cookies } from "react-cookie-consent";

export const initGA = () => {
    var trackingID = 'UA-86181221-11';

    if (Cookies.get('CookieConsent') == "true") {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGE === 'dev' || process.env.REACT_APP_STAGE === 'staging' || window.location.host === 'dev.pickyit.de' || window.location.host === 'staging.pickyit.de') {

        } else {
            ReactGA.initialize(trackingID);
            ReactGA.set({ anonymizeIp: true });
        }
    }
}

export const PageView = () => {
    ReactGA.pageview(window.location.pathname +
        window.location.search);
}

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */
export const Event = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
};

export const ModalView = (path) => {
    ReactGA.modalview(path);
}


export class Tracking extends Component {
    static displayName = Tracking.name;

    render() {
        return (
            <div>
            </div>
        );
    }
}
