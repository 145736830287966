import { faCheck, faStar, faTimes, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';
import { Event } from '../../components/Tracking';
import { SharingButtons } from '../SharingButtons/SharingButtons';
import { MoreResult } from './MoreResult';
import './Result.css';
import { TopResult } from './TopResult';
import './TopResultCard.css';
import Loader from '../Loader/Loader.js'

import { config } from '../../components/Constants';

var apiurl = config.url.API_URL;

export class Result extends Component {
    displayName = Result.name

    /**
     * sets all default values for States
     * @param {any} probs
     */
    constructor(probs) {
        super(probs);

        /**  Default states:
         * @param {boolean} error       handle errors of failed fetching
         * @param {boolean} isLoading   handles loading time so that the status can be reacted to
         * @param {array} answerState   pulls the answers from user from probs
         * @param responseResult        manages the handys as results of the fetching
         * @param {boolean} showDetailsModal   handle modalDetails visibility
         * @param data                  handle modalDetails data   
         */
        this.state = {
            error: null,
            isLoading: true,
            answersState: probs.probsAnswers,
            responseResult: [],
            viewNum: 0,
            viewIsSet: true,
            showDetailsModal: false,
            modalDetailsData: {},
            showPriceModal: false,
            showSurveyModal: false,
            modalPriceData: {}
        };
        this.switchTopResult = this.switchTopResult.bind(this);
        this.switchMoreResult = this.switchMoreResult.bind(this);
        this.showDetailsModal = this.showDetailsModal.bind(this);
        this.showPriceModal = this.showPriceModal.bind(this);

        this.onMouseOut = this.onMouseOut.bind(this);
        document.addEventListener("mouseout", this.onMouseOut);
    }

    showDetailsModal(deviceData) {
        this.setState({ modalDetailsData: deviceData });
        this.setState({ showDetailsModal: true });
    }

    showPriceModal(priceLink) {
        document.removeEventListener("mouseout", this.onMouseOut);
        this.setState({ modalPriceData: priceLink });
        this.setState({ showPriceModal: true });
    }

    /**
     * handles Result fetching
     * */
    getResult() {

        //zieht Antworten aus State in Variable
        var answers = this.state.answersState;

        //erstellt request body (JSON)
        var request = {
            "answer": answers
        }

        //wandelt request object in JSON um
        var raw = JSON.stringify(request);

        //nötiger Request Header
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        //setzt nötige options für Request zusammen (beinhaltet body und header)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        //resetet fetching-States
        this.setState({
            error: null,
            isLoading: true,
            responseResult: [],
            viewIsSet: false
        });

        //sendet request an URL inkl. der Options und setzt empfangenen Response als state
        fetch(apiurl+'/api/handy/makeResult', requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoading: false,
                        responseResult: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoading: false,
                        error
                    });
                }
            )
    }

    componentDidMount() {
        this.getResult();
    }

    /**
     * extrahiert Jahr als Date aus JSON
     * @param {any} json JSON die Datum enth&auml;lt
     */
    getYear(json) {
        var date = new Date(json);

        return date.getFullYear();
    }

    /**
     * errechnet prozentualen und gerundeten Score
     * @param {any} score
     */
    getScore(score) {
        return Math.round(score * 100);
    }

    /**
     * erstellt das Array für TopResukt (3 beste + Benchmark Dummy)
     * ruft TopResult Componente auf
     */
    switchTopResult() {
        var responseResult = this.state.responseResult;

        var results = responseResult.slice(0, 3);

        this.setState({
            children: <TopResult result={results} getLastQuestion={this.props.getLastQuestion} switch={this.switchMoreResult} showDetailsModal={this.showDetailsModal} showPriceModal={this.showPriceModal} />
        });
    }

    switchMoreResult() {
        var resultResponse = this.state.responseResult;

        var results = resultResponse.slice(3, resultResponse.length - 1);

        this.setState({
            children: <MoreResult result={results} switch={this.switchTopResult} showDetailsModal={this.showDetailsModal} showPriceModal={this.showPriceModal} />
        });
    }

    setView() {
        if (!this.state.viewIsSet) {
            switch (this.state.viewNum) {
                case 0:
                    this.switchTopResult()
                    break;
                case 1:
                    this.switchMoreResult()
                    break;
            }
            this.setState({ viewIsSet: true });
        }
    }

    onMouseOut(event) {
        if (event.clientY < 50) {
            document.removeEventListener("mouseout", this.onMouseOut);
            this.setState({ showSurveyModal: true });
        }
        
    }

    render() {

        var shareUrl = "pickyit.de";
        var shareSize = 32;
        var shareRound = true;

        //w&auml;hrend Ergebnis gefetched wird (isLoading = true), wird eine Ladenachricht angezeigt
        if (this.state.isLoading) {
            return <Loader />;
        }

        var responseResult = this.state.responseResult;
        var topResult = responseResult.slice(0, 1)[0][0];
        this.props.setTopResult(topResult);

        this.setView();

        let view = this.state.children;

        let modalDetails;

        let modalPrice;

        let header;
        if (this.state.viewNum == 0) {
            header = "Das sind deine Top Ergebnisse";
        } else {
            header = "Ger&auml;te, die gut zu deinen Anforderungen passen";
        }

        //setzt Jahr erst, wenn modalDetails gezeigt wird, da erst dannn sicher ist, dass State "Data" gefüllt ist
        if (this.state.showDetailsModal) {
            var models = this.state.modalDetailsData;

            var handy = models[0];

            var device = handy.handy;

            var score = handy.score;

            var year = this.getYear(handy.handy.launch_year);

            var modalHeader = handy.handy.brand.name + " " + handy.handy.device_name + " " + handy.handy.storage + "GB";

            var fulfilledReqs = handy.fulfilledReqs;
            var unfulfilledReqs = handy.unfulfilledReqs;
            var extraSpecs = handy.extraSpecs;

            var sim;
            var ip;

            if (handy.handy.body_sim != null && handy.handy.body_sim.toLowerCase().includes("dual sim")) {
                sim = "Ja";
            } else {
                sim = "Nein";
            }

            if (handy.handy.body_features != null && (handy.handy.body_features.toUpperCase().includes("IP66") || handy.handy.body_features.toUpperCase().includes("IP67") || handy.handy.body_features.toUpperCase().includes("IP68") || handy.handy.body_features.toUpperCase().includes("IP69") || handy.handy.body_features.toUpperCase().includes("IPX7") || handy.handy.body_features.toUpperCase().includes("IPX8") || handy.handy.body_features.toUpperCase().includes("IPX9") || handy.handy.body_features.toUpperCase().includes("IPX6"))) {
                ip = "Ja";
            } else {
                ip = "Nein";
            }

            modalDetails =
                <Modal className="resultDetailsModal" isOpen={this.state.showDetailsModal} toggle={() => this.setState({ showDetailsModal: false })}>
                    <div className="topResultCardBanner">
                        <div className="topResultCardScore">
                            <FontAwesomeIcon icon={faUserCheck} color="white" fixedWidth className="resultCardIcon" />
                            {score}%
                    </div>
                    </div>
                    { /*Eigenschaft Toggle händelt "X"-Button oben rechts im modalDetails*/}
                    < ModalHeader className="resultDetailsModalHeader" toggle={() => this.setState({ showDetailsModal: false })}>
                        {modalHeader}
                    </ModalHeader >

                    <ModalBody className="resultDetailsModalBody">
                        <Row className="rowNoMargin resultDetailsModalTopRow">
                            <div className="resultDetailsModalTopCol">
                                <img className="resultDetailsModalImg" src={handy.handy.image_url} alt="Card image cap" className="detailsResultCardImg" />
                            </div>
                            <div className="rowNoMargin resultmodelsStorageContainer resultDetailsModalStorageContainer">
                                {models.map((model, index) => (
                                    <Row className="resultmodelsStorageRow resultDetailsModalStorageRow">
                                        <Col className="resultmodelsStorageStorageCol resultDetailsModalStorageCol">{model.handy.storage}GB</Col>
                                        <Col className="resultmodelsStorageStorageCol resultDetailsModalStorageCol">{model.score}%</Col>
                                    </Row>
                                ))}
                            </div>
                        </Row>
                        <Row className="rowNoMargin resultDetailsModalTopRow resultDetailsModalProps">
                            <div className="probsRow topResultFulfilled">
                                {fulfilledReqs.map((Req, index) => (
                                    <Row className="probsRowInner">
                                        <FontAwesomeIcon icon={faCheck} color="#32DE84" fixedWidth className="resultCardIcon" />
                                        <h6>{Req}</h6>
                                    </Row>
                                ))}
                            </div>
                            <div className="probsRow topResultUnfulfilled">
                                {unfulfilledReqs.map((Req, index) => (
                                    <Row className="probsRowInner">
                                        <FontAwesomeIcon icon={faTimes} color="#de3234" fixedWidth className="resultCardIcon" />
                                        <h6>{Req}</h6>
                                    </Row>
                                ))}
                            </div>
                            <div className="probsRow topResultExtraSpecs">
                                {extraSpecs.map((Req, index) => (
                                    <Row className="probsRowInner">
                                        <FontAwesomeIcon icon={faStar} color="orange" fixedWidth className="resultCardIcon" />
                                        <h6>{Req}</h6>
                                    </Row>
                                ))}
                            </div>
                        </Row>
                        <SharingButtons device={device} />
                        <Row className="rowNoMargin resultDetailsModalSpecs">
                            <Table hover className="resultDetailsModalSpecsTable">
                                <tbody className="resultDetailsModalSpecsTableBody">
                                    <tr>
                                        <th scope="row">Betriebssystem</th>
                                        <td>{handy.handy.platform_os_type}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Preis</th>
                                        <td>{handy.handy.misc_price}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Jahr</th>
                                        <td>{year}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Gewicht</th>
                                        <td>{handy.handy.body_weight}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Akkulaufzeit</th>
                                        <td>{handy.handy.tests_battery_life}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Dxomark</th>
                                        <td>{handy.handy.test_dxomark}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Geekbench</th>
                                        <td>{handy.handy.test_GeekBench}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">GFXBench</th>
                                        <td>{handy.handy.test_GFXBench}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Größe (mm)</th>
                                        <td>{handy.handy.body_dimensions_h} x {handy.handy.body_dimensions_w} x {handy.handy.body_dimensions_d}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Auflösung</th>
                                        <td>{handy.handy.display_resolution_h} x {handy.handy.display_resolution_w}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">IP-Schutz</th>
                                        <td>{ip}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Dual-SIM</th>
                                        <td>{sim}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Farben</th>
                                        <td>{handy.handy.handyColors.map((color, index) => (
                                            <div class="resultDetailsModalSpecsColorsDiv" style={{ float: "right" }}> {color.colorsName},&nbsp;   </div>
                                        ))}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </ModalBody>
                </Modal >;
        }
        if (this.state.showPriceModal) {

            var device = this.state.modalPriceData;

            var priceLink = "https://www.idealo.de/preisvergleich/ProductCategory/19116.html?q=";
            priceLink += device.brand.name + " " + device.device_name + " " + device.storage + "GB";

            modalPrice =
                <Modal id='priceModal' isOpen={this.state.showPriceModal} toggle={() => { this.setState({ showPriceModal: false }); Event("Navigation", "PriceModal", "Close"); }}>

                    { /*Eigenschaft Toggle händelt "X"-Button oben rechts im modalDetails*/}
                    <ModalHeader toggle={() => { this.setState({ showPriceModal: false }); Event("Navigation", "PriceModal", "Close");}}>
                            Danke für dein Vertrauen.
                    </ModalHeader>

                    <ModalBody className='flex-modal'>
                        <p>Du bist einer unserer ersten Nutzer! Wir interessieren uns sehr für deine Meinung zu PickyIT. Wenn du drei Minuten Zeit hast, würden wir uns freuen,
                            wenn du an einer kurzen Umfrage teilnehmen würdest. Mit deinen Antworten werden wir den Service weiter für dich verbessern.
                        </p>
                        <Link onClick={() => Event("Navigation", "PriceModal", "Survey")} target="_blank" className="btn btn-prim" to="/Survey" style={{ padding: ".72rem" }}>Meinung teilen</Link>
                        <p>Anschließend gelangst du unten zum Preisvergleich.</p>

                        <a onClick={() => Event("Navigation", "PriceModal", "Price")} target="_blank" href={priceLink} className="btn btn-prim" style={{ padding: ".72rem" }}>Preisvergleich</a>
                    </ModalBody>
                </Modal>;
        }

        let modalSurvey =
            <Modal id='surveyModal' isOpen={this.state.showSurveyModal} toggle={() => this.setState({ showSurveyModal: false })}>
                <ModalHeader toggle={() => this.setState({ showSurveyModal: false })}>Danke für deinen Besuch!</ModalHeader>
                <ModalBody className='flex-modal'>
                    <p>Du bist einer unserer ersten Nutzer! Wir interessieren uns sehr für deine Meinung zu PickyIT. Wenn du drei Minuten Zeit hast, würden wir uns freuen,
                    wenn du an einer kurzen Umfrage teilnehmen würdest. Mit deinen Antworten werden wir den Service weiter für dich verbessern.</p>
                    <Link onClick={() => Event("Navigation", "SurveyModal", "Survey")} target="_blank" className="btn btn-prim" to="/Survey" style={{ padding: ".72rem" }}>Meinung teilen</Link>
                </ModalBody>
            </Modal>;
            

        return (
            <Container fluid className="resultContainer">
                {view}

                {modalDetails}

                {modalPrice}

                {modalSurvey}

            </Container>
        );
    }

}
