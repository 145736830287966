import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Form, FormGroup, FormText, Button, Input, Label } from 'reactstrap';
import { initGA, PageView } from '../Tracking';
import { Cookies } from "react-cookie-consent";

import './Contact.css';

import { config } from '../../components/Constants';

var apiurl = config.url.API_URL;

export class Contact extends Component {
    displayName = Contact.name

    componentDidMount() {
        initGA();
        PageView();
    }

    render() {
        return (
            <div id="contactWrapper">
                <Helmet>
                    <title>Kontakt - pickyIT</title>
                </Helmet>
                <h3>Du möchtest uns erreichen?<br />
                    <b>Wir freuen uns auf deine Nachricht.</b></h3>
                <Form action="javascript:void(0);">
                    <FormGroup>
                        <Label>Titel</Label>
                        <Input type="text" name="title" placeholder="Betreff"/>
                        <FormText color="muted">Wir wissen gerne direkt, wie wir dir weiterhelfen können.</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>Kontaktadresse</Label>
                        <Input type="email" name="address" placeholder="Deine E-Mail"/>
                        <FormText color="muted">Wir verwenden deine E-mail Adresse ausschließlich zum Antworten.</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>Nachricht</Label>
                        <Input type="textarea" name="text" placeholder="Deine Nachricht an uns..." />
                        <FormText color="muted">Wir antworten in der Regel innerhalb von 24 Stunden.</FormText>
                    </FormGroup>
                    <Button onClick={this.sendMessage} variant="primary">Verschicken</Button>
                </Form>
            </div>
        );
    }

    sendMessage() {

        var msg = {
            text: document.getElementsByName('text')[0].value,
            title: document.getElementsByName('title')[0].value,
            address: document.getElementsByName('address')[0].value
        }

        fetch(apiurl+'/api/contact/sendMail', {
            method: 'POST',
            body: JSON.stringify(msg),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>
            window.location.replace("/")
        ).catch(error => { alert("Es ist ein Fehler aufgetreten. Bitte erneut versuchen!") }
        );

        
 
    }

}