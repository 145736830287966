import React, { Component } from 'react';
import './AboutTeamItem.css';

export class AboutTeamItem extends Component {
    displayName = AboutTeamItem.name

    render() {

        var name = this.props.name;

        var role = this.props.role;

        var imgSrc = this.props.imgSrc;

        return (
            <div className="member">
                <img className="aboutTeamItemImg" src={imgSrc} />
                <div className="aboutTeamItemName">{name}</div>
                <div className="aboutTeamItemRole">{role}</div>
            </div >
        );
    }
}